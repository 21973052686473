import { gql } from "@apollo/client";
import {OrderItemFragment} from "./__generated__/OrderItemFragment";
import {ApiService} from "./service";
import {Product, ProductData} from "./products.service";
import {CartFragment} from "./__generated__/CartFragment";
import {PaymentProviderFragment} from "./__generated__/PaymentProviderFragment";
import {ProductConfigurationParameterInput} from "../../__generated__/globalTypes";
import {CreatedOrderFragment} from "./__generated__/CreatedOrderFragment";
import {CommonSlice} from "./common.slice";
import {
    CartFragmentQL,
    CreatedOrderFragmentQL,
    OrderDeliveryFragmentQL,
    OrderItemFragmentQL,
    OrderlySectorFragmentQL
} from "./fragments";
import {OrderlySectorFragment} from "./__generated__/OrderlySectorFragment";
import { utcTimeStringToLocalTime as utcToLocalTimeString } from "./utils";
import { OrderlyGeoLocationFragment } from "./__generated__/OrderlyGeoLocationFragment";
import {toast} from "react-toastify";


export interface ProductWithOptions {
    product: Product;
    options: ProductData[];
}

const GET_CART = gql`
query getCart {
    me {
        cart {
            ...CartFragment
        }
    }
}
${CartFragmentQL}
`;
export const getCart = async (): Promise<CartFragment> => {
    const resp = await ApiService.getClient()
        .query({query: GET_CART});
    return resp.data.me.cart;
};

export interface FullCartStatus {
    cart: CartFragment;
    common: Partial<CommonSlice>;
}
const GET_FULL_CART_STATUS = gql`
query getFullCartStatus {
    me {
        orderlyActiveSector {
            ...OrderlySectorFragment        
        } 
        cart {
            delivery {...OrderDeliveryFragment} 
        }
        user_cart: cart {
            ...CartFragment
        }    
    }
    orderlySectors {
        ...OrderlySectorFragment
    }
}
${OrderDeliveryFragmentQL}
${CartFragmentQL}
${OrderlySectorFragmentQL}
`;
export const getFullCartStatus = async (): Promise<FullCartStatus> => {
    const resp = await ApiService.getClient()
        .query({query: GET_FULL_CART_STATUS});
    const data = resp.data;
    return {
        cart: data.me.user_cart,
        common: {
            sectors: setupHoursInSectors(data.orderlySectors),
            selectedSector: setupHoursInSector(data.me.orderlyActiveSector),
            delivery: data.me.cart.delivery,
        }
    }
};

export const setupHoursInSector = (sector: OrderlySectorFragment) => {
    if (!sector) return sector;
    if (!sector.reservationHours) {
        sector.reservationHours = [];
        return sector;
    }
    try {
        for (const resTime of sector.reservationHours) {
            utcToLocalTimeString(resTime["start_time"]);
            utcToLocalTimeString(resTime["end_time"]);
        }
    } catch {
        console.error("Incorrect reservationHours: " + sector.reservationHours);
        sector.reservationHours = [];
    }
    
    return sector;
}
export const setupHoursInSectors = (sectors: OrderlySectorFragment[]) => {
    for (const sector of sectors) {
        setupHoursInSector(sector)
    }
    return sectors
}

const GET_ORDERLY_SECTORS = gql`
query getOrderlySectors {
    orderlySectors {
        ...OrderlySectorFragment
    }
}
${OrderlySectorFragmentQL}
`;
export const getOrderlySectors = async (): Promise<OrderlySectorFragment[]> => {
    const resp = await ApiService.getClient()
        .query({query: GET_ORDERLY_SECTORS});
    const data = resp.data;
    return setupHoursInSectors(data.orderlySectors);
};


const ADD_TO_CART = gql`
mutation addToCart($productId: ID!, $quantity: Int, $configuration: [ProductConfigurationParameterInput!]) {
    addCartProduct(productId: $productId, quantity: $quantity, configuration: $configuration) {
        ...OrderItemFragment
    }
}
${OrderItemFragmentQL}
`;
export const addToCart = async (product: ProductWithOptions, quantity: number): Promise<OrderItemFragment> => {
    let configuration: ProductConfigurationParameterInput[] = [];
    if (product.options) {
        configuration = product.options.map(option=>({key: "option", value: option.id}));
    }
    const resp = await ApiService.getClient()
        .mutate({
            mutation: ADD_TO_CART,
            variables: {
                productId: product.product.id,
                quantity,
                configuration
            }
        });
    return resp.data.addCartProduct;
};

const REMOVE_FROM_CART = gql`
mutation removeFromCart($orderItemId: ID!) {
    removeCartItem(itemId: $orderItemId) {
        ...OrderItemFragment    
    }
}
${OrderItemFragmentQL}
`;
export const removeFromCart = async (orderItem: OrderItemFragment) => {
    await ApiService.getClient()
        .mutate({
            mutation: REMOVE_FROM_CART,
            variables: {
                orderItemId: orderItem._id
            }
        });
};


const SET_CART_ITEM_QTY = gql`
mutation setCartItemQty($orderItemId: ID!, $quantity: Int) {
    updateCartItem(itemId: $orderItemId, quantity: $quantity) {
        ...OrderItemFragment    
    }
}
${OrderItemFragmentQL}
`;
export const setOrderPositionQty = async (orderItem: OrderItemFragment, quantity: number) => {
    await ApiService.getClient()
        .mutate({
            mutation: SET_CART_ITEM_QTY,
            variables: {
                orderItemId: orderItem._id,
                quantity: quantity
            }
        });
};


const SAVE_ORDER_ADDITIONAL_DATA = gql`
mutation saveOrderAdditionalData($seat: String, $phone: String, $reservationDate: String) {
    updateCart(
        meta: {
            seatNumber: $seat, 
            reservationDate: $reservationDate
        },
        contact: {
            telNumber: $phone
        },
        billingAddress:{}
    ) {
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const saveOrderAdditionalData = async (seat: string, phone: string, reservationDate?: Date) => {
    return (await ApiService.getClient()
        .mutate({
            mutation: SAVE_ORDER_ADDITIONAL_DATA,
            variables: {
                seat, 
                phone, 
                reservationDate: reservationDate ? reservationDate.toISOString() : undefined, 
            }
        })).data.updateCart;
};


const SAVE_ORDER_SEAT_NUMBER = gql`
mutation saveOrderSeatNumber($seat: String) {
    updateCart(
        meta: {
            seatNumber: $seat
        }
    ) {
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const saveOrderSeatNumber = async (seat: string) => {
    return (await ApiService.getClient()
        .mutate({
            mutation: SAVE_ORDER_SEAT_NUMBER,
            variables: {
                seat
            }
        })).data.updateCart;
};

const SAVE_ORDER_TIP = gql`
mutation saveOrderTipAmount($tipPercentage: Int) {
    updateCart(
        meta: {
            tip: $tipPercentage
        }
    ) {
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const saveOrderTip = async (tip: number) => {
    
    return (await ApiService.getClient()
        .mutate({
            mutation: SAVE_ORDER_TIP,
            variables: {
                tipPercentage: tip
            }
        })).data.updateCart;
};


const SAVE_ORDER_DELIVERY_MODE = gql`
mutation saveOrderDeliveryMode($isTableservice: Boolean, $geoLocation: JSON) {
    updateCart(
        meta: {
            isTableservice: $isTableservice,
            geoLocation: $geoLocation
        }
    ) {
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const saveOrderDeliveryMode = async (isTableservice: boolean, geoLocationEnabled: boolean) => {
    return (await ApiService.getClient()
        .mutate({
            mutation: SAVE_ORDER_DELIVERY_MODE,
            variables: {
                isTableservice: isTableservice ? isTableservice : undefined,
                geoLocation: geoLocationEnabled ? ({
                    accuracy: 0,
                    latitude: 0,
                    longitude: 0
                } as OrderlyGeoLocationInput) : undefined
            }
        })).data.updateCart;
}


export type OrderlyGeoLocationInput = Omit<OrderlyGeoLocationFragment, "__typename">;

const SAVE_ORDER_GEO_LOCATION = gql`
mutation saveOrderGeoLocation($geoLocation: JSON) {
    updateCart(
        meta: {
            geoLocation: $geoLocation
        }
    ) {
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const saveOrderGeoLocation = async (geoLocation: OrderlyGeoLocationInput) => {
    return (await ApiService.getClient()
        .mutate({
            mutation: SAVE_ORDER_GEO_LOCATION,
            variables: {
                geoLocation: geoLocation
            }
        })).data.updateCart;
}


export const SELECT_PAYMENT_PROVIDER = gql`
mutation selectPaymentProvider($paymentProviderId: ID!) {
    updateCart(paymentProviderId: $paymentProviderId) {
        _id payment { _id  }
    } 
}
`;
export const REQUEST_PAYMENT_LINK_POSTFINANCE = gql`
mutation getPaymentLinkPostfinance($orderPaymentId: ID!, $successUrl: String!, $failedUrl: String!) {
    signPaymentProviderForCheckout(
        orderPaymentId: $orderPaymentId,
        transactionContext: {
            successUrl: $successUrl
            failedUrl: $failedUrl
        }
    )
}`;

export const REQUEST_PAYMENT_LINK_INVOICE = gql`
mutation getPaymentLinkInvoice($orderId: ID!) {
    checkoutCart(orderId: $orderId) {
        _id
        status
    }
}`;

export const REQUEST_PAYMENT_LINK_SAFERPAY = gql`
mutation getPaymentLinkSaferpay($orderPaymentId: ID!, $returnUrl: String!) {
    signPaymentProviderForCheckout(
        orderPaymentId: $orderPaymentId,
        transactionContext: {
            ReturnUrl: {
                Url: $returnUrl
            }
        }
    )
}`;

export const REQUEST_PAYMENT_LINK_DATATRANS = gql`
mutation getPaymentLinkDatatrans($orderPaymentId: ID!, $successUrl: String!, $failedUrl: String!) {
    signPaymentProviderForCheckout(
        orderPaymentId: $orderPaymentId,
        transactionContext: {
            option: {
              createAlias: false,
            },
            redirect: {
                successUrl: $successUrl
                cancelUrl: $failedUrl
                errorUrl: $failedUrl
            }
        }
    )
}`;

const paymentLinkMap: Record<string, {
    (params: {orderId: string, orderPaymentId: string}, client: any): Promise<string>
}> = {
    "shop.unchained.invoice": async ({ orderId }, client) => {
        await client.mutate({
            mutation: REQUEST_PAYMENT_LINK_INVOICE,
            variables: {
                orderId,
            }
        });
        return `${window.location.origin}/order?order_id=${orderId}`;
    },
    "shop.unchained.payment.demo": async ({ orderId }, client) => {
        await client.mutate({
            mutation: REQUEST_PAYMENT_LINK_INVOICE,
            variables: {
                orderId,
            }
        });
        return `${window.location.origin}/order?order_id=${orderId}`;
    },
    "shop.unchained.datatrans": async ({ orderPaymentId, orderId }, client) => {
        const r = await client.mutate({
            mutation: REQUEST_PAYMENT_LINK_DATATRANS,
            variables: {
                orderPaymentId,
                successUrl: `${window.location.origin}/order?order_id=${orderId}`,
                failedUrl: `${window.location.origin}/payment_error?order_id=${orderId}`,
            }
        });
        const data = JSON.parse(r.data.signPaymentProviderForCheckout);
        return data.location;
    },
    "shop.unchained.postfinance": async ({ orderPaymentId, orderId }, client) => {
        const r = await client.mutate({
            mutation: REQUEST_PAYMENT_LINK_POSTFINANCE,
            variables: {
                orderPaymentId,
                successUrl: `${window.location.origin}/order?order_id=${orderId}`,
                failedUrl: `${window.location.origin}/payment_error?order_id=${orderId}`,
            }
        });
        const data = JSON.parse(r.data.signPaymentProviderForCheckout);
        return data.location;
    },
    "shop.unchained.payment.saferpay": async ({ orderPaymentId, orderId }, client) => {
        const r = await client.mutate({
            mutation: REQUEST_PAYMENT_LINK_SAFERPAY,
            variables: {
                orderPaymentId,
                returnUrl: `${window.location.origin}/order?order_id=${orderId}`,
            }
        });
        const data = JSON.parse(r.data.signPaymentProviderForCheckout);
        return data.location;
    },
}

export const requestPaymentLink = async (provider: PaymentProviderFragment, orderId: string) => {
    const rs = await ApiService.getClient()
        .mutate({
            mutation: SELECT_PAYMENT_PROVIDER,
            variables: {
                paymentProviderId: provider._id
            }
        });
    const providerId = provider.interface?._id as string;
    const orderPaymentId = rs.data.updateCart.payment._id;
    const mutation = paymentLinkMap[providerId];

    return mutation({
        orderId,
        orderPaymentId,
    }, ApiService.getClient());
};



export const GET_ORDER_BY_ID = gql`
query GetOrderById($orderId: ID!) {
    order(orderId: $orderId) {
        ...CreatedOrderFragment
    }
}
${CreatedOrderFragmentQL}
`;
export const getOrderById = async (orderId: string): Promise<CreatedOrderFragment|undefined> => {
    const resp = await ApiService.getClient()
        .query({
            query: GET_ORDER_BY_ID,
            variables: {orderId}
        });
    return resp.data.order;
};


export interface OrderProblem {
    orderNumber: string;
    orderId?: string;
    reason: string;
    phoneNumber: string;
    emailAddress: string;
}

const REPORT_ERROR = gql`
mutation ReportError($orderNumber: String!, $reason: String, $phoneNumber: String, $emailAddress: String, $orderId: String) {
    orderlyReportError(
        orderNumber: $orderNumber,
        reason: $reason,
        phoneNumber: $phoneNumber,
        emailAddress: $emailAddress,
        orderId: $orderId
    )
}
`;
export const reportProblem = async (orderNumber: string, reason: string, phoneNumber: string, emailAddress: string, orderId?: string) => {
    await ApiService.getClient()
        .mutate({
            mutation: REPORT_ERROR,
            variables: {
                orderNumber, reason, phoneNumber, emailAddress, orderId
            }
        });
    const problem: OrderProblem = {
        orderNumber, reason, phoneNumber, emailAddress, orderId
    };
    const problemStr = JSON.stringify(problem);
    localStorage.setItem(`problem-${orderNumber}`, problemStr);
    if (orderId) {
        localStorage.setItem(`problem-${orderId}`, problemStr);
    }
};

export const getOrderProblemByOrderId = async (orderId: string): Promise<OrderProblem|undefined> => {
    return getProblemByKey(`problem-${orderId}`);
};

export const getOrderProblemByOrderNumber = async (orderNumber: string): Promise<OrderProblem|undefined> => {
    return getProblemByKey(`problem-${orderNumber}`);
};

const getProblemByKey = (key: string): OrderProblem|undefined => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return undefined;
    try {
        return JSON.parse(itemStr);
    } catch {
        return undefined;
    }
};

export const GET_ORDERS = gql`
query GetOrders {
    me {
        orders {
            ...CreatedOrderFragment
        }
    }
}
${CreatedOrderFragmentQL}
`;
export const getOrders = async (): Promise<CreatedOrderFragment[]> => {
    const resp = await ApiService.getClient()
        .query({
            query: GET_ORDERS,
        });
        
    return resp.data?.me?.orders || [];
};


const MARK_ORDER_AS_DELIVERED = gql`
mutation MarkDelivered($orderId: ID!) {
    deliverOrder(orderId: $orderId) {
        ...CreatedOrderFragment
    }
}
${CreatedOrderFragmentQL}
`;
export const markOrderAsDelivered = async (orderId: string) => {
    try {
        const resp = await ApiService.getClient()
            .mutate({
                mutation: MARK_ORDER_AS_DELIVERED,
                variables: {
                    orderId
                }
            });
        toast.success("Done", {
            hideProgressBar: true
        });
        return resp.data.deliverOrder;
    } catch (e) {
        toast.error("Error: "+(e as Error).toString(), {
            hideProgressBar: true
        });
    }
}

const SEND_NOT_FOUND_NOTIFICATION = gql`
mutation Send($orderId: ID!) {
    sendOrderlyNotFoundNotification(orderId: $orderId) {
        ...CreatedOrderFragment
    }
}
${CreatedOrderFragmentQL}
`;
export const sendNotFoundNotification = async (orderId: string) => {
    try {
        const resp = await ApiService.getClient()
            .mutate({
                mutation: SEND_NOT_FOUND_NOTIFICATION,
                variables: {
                    orderId
                }
            });
        toast.success("Done", {
            hideProgressBar: true
        });
        return resp.data.sendOrderlyNotFoundNotification;
    } catch (e) {
        toast.error("Error: "+(e as Error).toString(), {
            hideProgressBar: true
        });
    }
}